import React from 'react'
import styled from '@emotion/styled'
import { breakpoints, colors, fonts, fontSizes } from 'src/utils/styles'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { useTranslation } from 'react-i18next'

const StStickyHeader = styled.div`
  display: flex;
  font-family: ${props => (props.isNFL ? fonts.nightwraith : fonts.extendedBold)};
  color: ${props => (props.isNFL ? colors.nflHighlightYellow : props.textColor)};
  font-size: ${props =>
    props.isNFL ? fontSizes.stickyBannerBigText : fontSizes.subHeadlineDefault};
  justify-items: center;
  justify-content: center;
  align-items: center;
  height: 1.5rem;
  padding-bottom: 0.3rem;
  background-color: ${props => (props.isNFL ? colors.nflBlue : props.bgColor)};
  position: sticky;
  top: 0;
  z-index: 4;

  @media (max-width: ${breakpoints.m}px) {
    font-size: ${props =>
      props.isNFL ? fontSizes.stickyBannerSmallText : fontSizes.subHeadlineDefault};
  }
`

const StStickyBarLink = styled(Link)`
  color: ${props => (props.isNFL ? colors.nflHighlightYellow : props.textColor)};
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
  width: 100%;
  height: 100%;
`

const StickyHeader = ({ className, isNFL }) => {
  const { i18n } = useTranslation()
  const isEnglish = i18n.language === 'en'
  const isFrench = i18n.language === 'fr'

  const data = useStaticQuery(graphql`
    query StickyBarQuery {
      file(name: { eq: "stickyBar" }) {
        childMarkdownRemark {
          frontmatter {
            isActive
            stickyBarText
            stickyBarTextEn
            stickyBarTextFr
            stickyBarBgColor
            stickyBarLinkUrl
            stickyBarTextColor
          }
        }
      }
    }
  `)

  const getStickyBarText = () => {
    const translations = data?.file?.childMarkdownRemark?.frontmatter

    if (isEnglish) return translations?.stickyBarTextEn || ''
    if (isFrench) return translations?.stickyBarTextFr || ''
    return translations?.stickyBarText || ''
  }

  const isActive = data?.file?.childMarkdownRemark?.frontmatter?.isActive || false
  const stickyBarBgColor =
    data?.file?.childMarkdownRemark?.frontmatter?.stickyBarBgColor || '#de6f46'
  const stickyBarLinkUrl = data.file?.childMarkdownRemark?.frontmatter?.stickyBarLinkUrl || ''
  const stickyBarTextColor =
    data?.file?.childMarkdownRemark?.frontmatter?.stickyBarTextColor || '#FFFFFF'

  function renderStickyHeader() {
    if (isActive && !isNFL) {
      return (
        <StStickyHeader
          className={className}
          bgColor={stickyBarBgColor}
          isNFL={isNFL}
          textColor={stickyBarTextColor}
        >
          {!!stickyBarLinkUrl ? (
            <StStickyBarLink to={stickyBarLinkUrl} textColor={stickyBarTextColor}>
              {getStickyBarText()}
            </StStickyBarLink>
          ) : (
            getStickyBarText()
          )}
        </StStickyHeader>
      )
    } else if (isNFL) {
      return (
        <StStickyHeader className={className} bgColor={stickyBarBgColor} isNFL={isNFL}>
          Exclusive Pre Order!
        </StStickyHeader>
      )
    } else return null
  }

  return renderStickyHeader()
}

export default StickyHeader
