import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import styled from '@emotion/styled'
import StoreProvider from 'src/provider/ContextProvider'
import { breakpoints, colors, GlobalStyle } from 'src/utils/styles'
import Navigation from 'src/components/Navigation'
import Footer from 'src/components/Footer'
import CookieConsent from 'react-cookie-consent'
import CloseIconSvg from 'src/static/icons/svg/ll_close.svg'
import { useTranslation } from 'react-i18next'
import PopUp from 'src/components/PopUp'
import StickyHeader from '../components/StickyHeader'

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 1920px;
  padding: 0;
  position: relative;
  min-height: calc(100vh - 245px);
`

const StSvgClose = styled(CloseIconSvg)`
  width: 20px;
  height: 20px;

  g {
    line {
      stroke: #ffffff !important;
    }
  }
`

const StCookieText = styled.p`
  a {
    text-decoration: underline;
    color: white;
  }

  a:hover {
    text-decoration: underline;
  }
  @media (max-width: ${breakpoints.s}px) {
    font-size: 10px;
    width: 110%;
  }
`

const StStickyHeader = styled(StickyHeader)`
  display: flex;
  @media (max-width: ${breakpoints.l}px) {
    display: none;
  }
`

const Layout = ({ children, location, pageContext }) => {
  const { t } = useTranslation('cookies')

  const CloseCookieBannerButton = props => (
    <div {...props}>
      <StSvgClose />
    </div>
  )

  let popUpBanner
  if (
    location.pathname === '/de' ||
    location.pathname === '/de/' ||
    location.pathname === '/en' ||
    location.pathname === '/en/' ||
    location.pathname === '/fr' ||
    location.pathname === '/fr/' ||
    location.pathname === '/'
  ) {
    popUpBanner = null
  } else {
    popUpBanner = <PopUp />
  }

  const siteTitleData = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <StoreProvider>
      <GlobalStyle />
      <>
        <StStickyHeader isNFL={false} />
        <Navigation siteTitle={siteTitleData.site.siteMetadata.title} location={location} />
        <Wrapper>{children}</Wrapper>
        <Footer />
      </>
      {popUpBanner}
      <CookieConsent
        ButtonComponent={CloseCookieBannerButton}
        location="bottom"
        cookieName="lobsterAndLemonadeCookieAccpeted"
        style={{
          background: colors.darkGrayNoInverter,
          textAlign: 'center',
          alignItems: 'center',
          minHeight: '60px',
          justifyContent: 'flex-end',
          flexWrap: 'no-wrap',
        }}
        buttonStyle={{
          backgroundColor: 'unset',
          width: '20px',
          height: '20px',
        }}
        contentStyle={{
          margin: '.25rem .5rem',
          padding: 0,
          flex: '1 1 300px',
        }}
        expires={150}
      >
        <StCookieText
          dangerouslySetInnerHTML={{
            __html: t('cookies.text'),
          }}
        />
      </CookieConsent>
    </StoreProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
